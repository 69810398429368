console.log('process.env',process.env)
const serverList = [
  {
    BASEURL: 'http://192.168.1.26:8305',
  },
  {
    BASEURL: 'https://gameapi.sw007.cn',
  },
  {
    BASEURL: 'https://game.sw007.cn/api',
  },
];
let serverArr = serverList[2]
export default serverArr
